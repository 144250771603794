import { CheckIcon, ClockIcon, SyncIcon, ZapIcon, DotFillIcon, XIcon, TriangleDownIcon } from '@primer/octicons-react';
import * as React from 'react';
export default {
  CheckIcon,
  ClockIcon,
  SyncIcon,
  ZapIcon,
  DotFillIcon,
  XIcon,
  TriangleDownIcon,
  React
};